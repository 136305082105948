import {createAsyncThunk}         from '@reduxjs/toolkit';
import * as service               from "services/orderStatus";
import {PREFIX}                   from "./consts";
import {getFormattedPaymentInfo}  from "pages/order-status/helpers";
import {removeItem}               from "utils/local-storage";
import LS_KEY                     from "utils/local-storage/consts";

import type {RootState}           from "store";

export const getPaymentInfo = createAsyncThunk(
  `${PREFIX}/load`,
  async ({orderId, isSuccess}: {orderId: string | null, isSuccess: boolean}, {rejectWithValue, getState}) => {
    if (!orderId) {return rejectWithValue('Нет orderId');}

    if (isSuccess) {
      const model = getState() as RootState;
      const id = model.market?.marketId;
      // Очищаем sid корзины успешного заказа
      removeItem(`${LS_KEY.MARKET}${id}`);
    }

    try {
      const res = await service.getStatus({orderId});
      return getFormattedPaymentInfo(res.data);
    } catch (e) {
      const err = e as Error;
      return rejectWithValue(err.response.data.code);
    }
  }
);

export const openPayment = createAsyncThunk(
  `${PREFIX}/payment`,
  async ({orderId}: {orderId: string | null}, {rejectWithValue}) => {
    if (!orderId) {return rejectWithValue('Нет orderId');}

    try {
      const res = await service.retryPayment(orderId);
      const paymentUrl = res.data?.payment_data?.init_url;
      if (paymentUrl) {
        window.location.href = paymentUrl;
      }
    } catch (e) {
      const err = e as Error;
      return rejectWithValue(err.response.data.code);
    }
  }
);
