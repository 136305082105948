export const MARKET_SCREEN = {
  CATEGORIES: 'CATEGORIES',
  PRODUCTS: 'PRODUCTS',
  PRODUCT: 'PRODUCT',
  CART: 'CART',
  CONFIRMATION: 'CONFIRMATION',
  STATUS: 'STATUS'
} as const;

export const PRODUCT_TYPE = {
  GOODS: 'goods',
  SERVICES: 'services'
} as const;

export const PAYMENT_TYPES = {
  AFTER_CONFIRM: 'after_confirmation',
  POST_PAID: 'postpaid',
  PREPAID: 'prepaid'
};

export const ORDER_STATUS = {
  NEW: "new",
  CONFIRMED: "confirmed"
};

export const INVOICE_STATUS = {
  NOT_PAID: "notpaid"
};

export const GV_BILLING = "gv_billing";
